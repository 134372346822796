<template>
  <div>
    <Table
      title-key="user.username"
      :actions="actions"
      :items="$store.state.withdrawal.tableData"
      :fields="$store.getters['withdrawal/tableFields']"
      :loading="$store.state.withdrawal.loadingTable"
      :filter="$store.state.withdrawal.tableFilter"
      :sort="$store.state.withdrawal.tableSort"
      :tab-options="tabOptions"
      :sort-options="sortOptions"
      :keyword="$store.state.withdrawal.tableKeyword"
      :pagination="$store.state.withdrawal.tablePagination"
      :selected-ids="$store.state.withdrawal.selectedIds"
      search-placeholder="Search acc. name, email"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
      @select="select"
      @download="downloadData"
    />

    <!-- Approve confirmation modal -->
    <b-modal
      v-model="approvalModalVisible"
      title="Approve"
      ok-title="Yes, approve"
      cancel-variant="white"
      :ok-disabled="$store.state.withdrawal.loadingSubmit"
      @ok.prevent="approve"
    >
      Are you sure you want to approve this withdrawal request? The money will be sent to the creator.
    </b-modal>
    <b-modal
      v-model="approvalMultipleRowModalVisible"
      title="Approve"
      ok-title="Yes, approve"
      cancel-variant="white"
      :ok-disabled="$store.state.withdrawal.loadingSubmit"
      @ok.prevent="approveMultipleRow"
    >
      Are you sure you want to approve these {{ $store.state.withdrawal.selectedIds.length }} withdrawal requests? The money will be sent to the creator.
    </b-modal>

    <!-- Refund confirmation modal -->
    <b-modal
      v-model="refundModalVisible"
      title="Refund"
      ok-title="Yes, refund"
      cancel-variant="white"
      :ok-disabled="$store.state.withdrawal.loadingSubmit"
      @ok.prevent="refund"
    >
      Are you sure you want to refund this withdrawal request? The money <b>won't</b> be credited back to the creator.
    </b-modal>
    <b-modal
      v-model="refundMultipleRowModalVisible"
      title="Refund"
      ok-title="Yes, refund"
      cancel-variant="white"
      :ok-disabled="$store.state.withdrawal.loadingSubmit"
      @ok.prevent="refundMultipleRow"
    >
      Are you sure you want to refund these {{ $store.state.withdrawal.selectedIds.length }} withdrawal requests? The money <b>won't</b> be credited back to the creator.
    </b-modal>
    <b-modal
      v-model="rejectionMultipleRowModalVisible"
      title="Reject"
      hide-footer
    >
      Are you sure you want to reject these {{ $store.state.withdrawal.selectedIds.length }} withdrawal requests? The money will be credited back to the creator.

      <FormBuilder
        :fields="rejectionFields"
        :loading-submit="$store.state.withdrawal.loadingSubmit"
        :success-message="`${$store.state.withdrawal.selectedIds.length} withdrawal has been rejected`"
        submit-label="Yes, reject"
        block
        @submit="rejectMultipleRow"
        @cancel="rejectionMultipleRowModalVisible = false"
      />
    </b-modal>

    <!-- Reject confirmation modal -->
    <b-modal
      v-model="rejectionModalVisible"
      title="Reject"
      hide-footer
    >
      <p>Are you sure you want to reject this withdrawal request? The money will be credited back to the creator.</p>

      <FormBuilder
        :fields="rejectionFields"
        :loading-submit="$store.state.withdrawal.loadingSubmit"
        success-message="'The withdrawal has been rejected'"
        submit-label="Yes, reject"
        block
        @submit="reject"
        @cancel="rejectionModalVisible = false"
      />
    </b-modal>

    <!-- Approve ID Card verification modal -->
    <b-modal
      v-model="verifiedIdModalVisible"
      title="Approve"
      ok-title="Yes, approve"
      cancel-variant="danger"
      cancel-title="Reject"
      :ok-disabled="$store.state.withdrawal.loadingSubmit"
      @ok.prevent="verify(true)"
      @cancel="rejectIdModalVisible = true"
    >
      Are you sure you want to approve this account's verification ID?
    </b-modal>

    <!-- Reject ID Card verification modal -->
    <b-modal
      v-model="rejectIdModalVisible"
      title="Reject"
      ok-title="Yes, reject"
      cancel-title="Cancel"
      :ok-disabled="$store.state.user.loadingSubmit"
      @ok.prevent="verify(false, rejectReason)"
    >
      Are you sure you want to reject this account's verification ID?
      <div class="py-1">
        <input
          v-model="rejectReason"
          placeholder="Write your reason here"
          style="width: 100%"
        >
      </div>

    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import FormBuilder from '@/layouts/components/FormBuilder.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    Table,
    BModal,
    FormBuilder,
  },
  data() {
    return {
      // Modal
      approvalModalVisible: false,
      approvalMultipleRowModalVisible: false,
      rejectionModalVisible: false,
      rejectionMultipleRowModalVisible: false,
      refundModalVisible: false,
      refundMultipleRowModalVisible: false,
      verifiedIdModalVisible: false,
      rejectIdModalVisible: false,
      rejectReason: '',
      selectedData: '',

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      approvalFields: [
        {
          key: 'description',
          label: 'Reason',
          type: 'textarea',
          minLength: 5,
        },
      ],

      rejectionFields: [
        {
          key: 'reason',
          label: 'Reason',
          type: 'textarea',
          minLength: 5,
        },
      ],

      actions: [
        'view',
        'download',
        {
          type: 'other',
          children: [
            {
              label: 'Approve',
              event: this.confirmApprove,
              multipleRowEvent: this.confirmMultipleRowApprove,
              visibility: {
                callback: data => ((data.verificationPictureURL && data.verified && data.status === 'pending') || (!data.verificationPictureURL && data.status === 'pending')) && this.canAccess('manage', 'Withdrawal'),
              },
            },
            {
              label: 'Refund',
              event: this.confirmRefund,
              multipleRowEvent: this.confirmMultipleRowRefund,
              visibility: {
                callback: data => ((data.verificationPictureURL && data.verified && data.status === 'pending') || (!data.verificationPictureURL && data.status === 'pending')) && this.canAccess('manage', 'Withdrawal'),
              },
            },
            {
              label: 'Reject',
              event: this.confirmReject,
              multipleRowEvent: this.confirmMultipleRowReject,
              visibility: {
                callback: data => ((data.verificationPictureURL && data.verified && data.status === 'pending') || (!data.verificationPictureURL && data.status === 'pending')) && this.canAccess('manage', 'Withdrawal'),
              },
            },
            {
              label: 'Verify ID',
              event: this.confirmVerify,
              visibility: {
                callback: data => data.verificationPictureURL && !data.verified && this.canAccess('manage', 'Withdrawal'),
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    tabOptions() {
      return [
        { key: 'statuses', label: 'All' },
        {
          key: 'statuses', label: 'Pending', value: 'pending', tag: this.$store.state.dashboard.pendingWithdrawalCount, default: true,
        },
        { key: 'statuses', label: 'Approved', value: 'approved' },
        { key: 'statuses', label: 'Rejected', value: 'rejected' },
        { key: 'statuses', label: 'Refunded', value: 'refund' },
      ]
    },
  },
  methods: {
    loadData() {
      return this.$store.dispatch('withdrawal/getTableData')
    },
    changeFields(val) {
      this.$store.commit('withdrawal/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('withdrawal/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('withdrawal/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('withdrawal/SET_TABLE_SORT', val)
    },
    confirmApprove(data) {
      this.selectedData = data.item
      this.approvalModalVisible = true
    },
    confirmMultipleRowApprove() {
      this.approvalMultipleRowModalVisible = true
    },
    approve() {
      this.$store.dispatch('withdrawal/changeStatus', {
        id: this.selectedData.id,
        data: {
          status: 'approved',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The withdrawal has been approved',
              variant: 'success',
            },
          })

          this.approvalModalVisible = false
          this.loadData()
        })
    },
    approveMultipleRow() {
      this.$store.dispatch('withdrawal/changeStatusMultiple', {
        ids: this.$store.state.withdrawal.selectedIds,
        status: 'approved',
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: `${this.$store.state.withdrawal.selectedIds.length} withdrawal has been approved`,
              variant: 'success',
            },
          })

          this.approvalMultipleRowModalVisible = false
          this.loadData()

          // reset selected rows
          this.select([])
        })
    },
    confirmRefund(data) {
      this.selectedData = data.item
      this.refundModalVisible = true
    },
    confirmMultipleRowRefund() {
      this.refundMultipleRowModalVisible = true
    },
    refund() {
      this.$store.dispatch('withdrawal/changeStatus', {
        id: this.selectedData.id,
        data: {
          status: 'refund',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The withdrawal has been refunded',
              variant: 'success',
            },
          })

          this.refundModalVisible = false
          this.loadData()
        })
    },
    confirmMultipleRowReject() {
      this.rejectionMultipleRowModalVisible = true
    },
    refundMultipleRow() {
      this.$store.dispatch('withdrawal/changeStatusMultiple', {
        ids: this.$store.state.withdrawal.selectedIds,
        status: 'refund',
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: `${this.$store.state.withdrawal.selectedIds.length} withdrawal has been refunded`,
              variant: 'success',
            },
          })

          this.refundMultipleRowModalVisible = false
          this.loadData()

          // reset selected rows
          this.select([])
        })
    },
    confirmReject(data) {
      this.selectedData = data.item
      this.rejectionModalVisible = true
    },
    rejectMultipleRow() {
      this.$store.dispatch('withdrawal/changeStatusMultiple', {
        ids: this.$store.state.withdrawal.selectedIds,
        status: 'rejected',
      })
        .then(() => {
          this.rejectionMultipleRowModalVisible = false
          this.loadData()

          // reset selected rows
          this.select([])
        })
    },
    reject(data, callback) {
      this.$store.dispatch('withdrawal/changeStatus', {
        id: this.selectedData.id,
        data: {
          ...data,
          status: 'rejected',
        },
      })
        .then(() => {
          this.rejectionModalVisible = false
          this.loadData()

          callback()
        })
    },
    confirmVerify(data) {
      this.selectedData = data.item
      this.verifiedIdModalVisible = true
    },
    verify(status, reason) {
      const id = this.selectedData.userID
      this.$store.dispatch('withdrawal/verifyID', { id, status, reason })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: `The verification has been ${status ? 'approved' : 'rejected'}`,
              variant: `${status ? 'success' : 'danger'}`,
            },
          })

          this.verifiedIdModalVisible = false
          this.rejectIdModalVisible = false
          this.rejectReason = ''
          this.loadData()
        })
    },
    paginate(val) {
      this.$store.commit('withdrawal/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('withdrawal/SET_TABLE_KEYWORD', val)
    },
    select(val) {
      this.$store.commit('withdrawal/SET_SELECTED_IDS', val)
    },
    downloadData() {
      return this.$store.dispatch('withdrawal/downloadTableData')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Your data will be ready in a minutes!',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'export-result' })
        })
    },
  },
}
</script>
